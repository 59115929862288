import "./ThemeBlog.scss";

const ThemeBlog = (props) => {
    const {
        allSanPham,
        VND,
        nhomSP,
        handleChiTietSP,
        handleDaThemGioHang,
        handleThemGioHang,
        cartDemo,
        setTTMenu,
    } = props;
    const chieuRongMH = window.innerWidth;

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThemeBlog-ConTaiNer">
                        <div
                            onClick={() => setTTMenu("hien")}
                            className="kiHieu-nhomSanPham2-menu"
                        >
                            <div className="nhomSanPham2">{nhomSP}</div>
                            <div className="menu">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                        <div className="sanPham-container">
                            {allSanPham &&
                                allSanPham?.map((item, index) => {
                                    return (
                                        <div key={index} className="sanPham">
                                            <div className="tenSP-themGH">
                                                <div
                                                    onClick={() =>
                                                        handleChiTietSP(item)
                                                    }
                                                >
                                                    {item?.TenSanPham?.length >
                                                    26 ? (
                                                        <div className="tenSP">
                                                            <span>
                                                                {item?.TenSanPham.slice(
                                                                    0,
                                                                    1
                                                                )}
                                                            </span>
                                                            &#160;
                                                            {item?.TenSanPham.slice(
                                                                0,
                                                                26
                                                            )}
                                                            ...
                                                        </div>
                                                    ) : (
                                                        <div className="tenSP">
                                                            <span>
                                                                {item?.TenSanPham.slice(
                                                                    0,
                                                                    1
                                                                )}
                                                            </span>
                                                            &#160;
                                                            {item.TenSanPham}
                                                        </div>
                                                    )}
                                                </div>

                                                {cartDemo &&
                                                cartDemo?.find(
                                                    (item2) =>
                                                        item2._id === item._id
                                                ) ? (
                                                    <div
                                                        onClick={() =>
                                                            handleDaThemGioHang(
                                                                item
                                                            )
                                                        }
                                                        className="daThem"
                                                    >
                                                        THÊM GIỎ HÀNG
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={() =>
                                                            handleThemGioHang(
                                                                item
                                                            )
                                                        }
                                                        className="themGH"
                                                    >
                                                        THÊM GIỎ HÀNG
                                                    </div>
                                                )}
                                            </div>
                                            <div className="tieuDe-giaBan-giaCu">
                                                <div className="tieuDe">
                                                    Giá Bán
                                                </div>
                                                {item?.allDacDiemSP &&
                                                    item?.allDacDiemSP?.length >
                                                        0 &&
                                                    item?.allDacDiemSP?.map(
                                                        (item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className="giaBan-giaCu"
                                                                >
                                                                    <div className="giaBan">
                                                                        -&ensp;
                                                                        {
                                                                            item?.tenDacDiem
                                                                        }
                                                                        &ensp;:&ensp;
                                                                        <span className="giaKM">
                                                                            {VND.format(
                                                                                item?.giaKhuyenMai
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                    <div className="giaCu">
                                                                        {VND?.format(
                                                                            item?.giaNiemYet
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>

                                            <div
                                                onClick={() =>
                                                    handleChiTietSP(item)
                                                }
                                                className="thongTinSp"
                                            >
                                                <div className="tieuDeTT">
                                                    Thông Tin Sản Phẩm
                                                </div>
                                                <div className="noiDungTT">
                                                    . . . xem thêm!
                                                </div>
                                            </div>
                                            <div className="allAnh-container">
                                                {item?.allDacDiemSP?.length ===
                                                    1 && (
                                                    <div
                                                        onClick={() =>
                                                            handleChiTietSP(
                                                                item
                                                            )
                                                        }
                                                        className="allAnh1"
                                                    >
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[0]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh1"
                                                            alt="fabysa.com"
                                                        />
                                                    </div>
                                                )}
                                                {item?.allDacDiemSP?.length ===
                                                    2 && (
                                                    <div
                                                        onClick={() =>
                                                            handleChiTietSP(
                                                                item
                                                            )
                                                        }
                                                        className="allAnh2"
                                                    >
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[0]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh1"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[1]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh2"
                                                            alt="fabysa.com"
                                                        />
                                                    </div>
                                                )}
                                                {item?.allDacDiemSP?.length ===
                                                    3 && (
                                                    <div
                                                        onClick={() =>
                                                            handleChiTietSP(
                                                                item
                                                            )
                                                        }
                                                        className="allAnh3"
                                                    >
                                                        <div className="anh1-anh2">
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.allDacDiemSP[0]
                                                                        .AnhSanPham
                                                                }
                                                                className="anh1"
                                                                alt="fabysa.com"
                                                            />
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.allDacDiemSP[1]
                                                                        .AnhSanPham
                                                                }
                                                                className="anh2"
                                                                alt="fabysa.com"
                                                            />
                                                        </div>
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[1]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh3"
                                                            alt="fabysa.com"
                                                        />
                                                    </div>
                                                )}
                                                {item?.allDacDiemSP?.length ===
                                                    4 && (
                                                    <div
                                                        onClick={() =>
                                                            handleChiTietSP(
                                                                item
                                                            )
                                                        }
                                                        className="allAnh4"
                                                    >
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[0]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh4"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[1]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh4"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[2]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh4"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[3]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh4"
                                                            alt="fabysa.com"
                                                        />
                                                    </div>
                                                )}
                                                {item?.allDacDiemSP?.length >
                                                    4 && (
                                                    <div
                                                        onClick={() =>
                                                            handleChiTietSP(
                                                                item
                                                            )
                                                        }
                                                        className="allAnh5"
                                                    >
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[0]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh5"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[1]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh5"
                                                            alt="fabysa.com"
                                                        />
                                                        <img
                                                            src={
                                                                item
                                                                    ?.allDacDiemSP[2]
                                                                    .AnhSanPham
                                                            }
                                                            className="anh5"
                                                            alt="fabysa.com"
                                                        />
                                                        <div className="anh6">
                                                            <img
                                                                src={
                                                                    item
                                                                        ?.allDacDiemSP[3]
                                                                        .AnhSanPham
                                                                }
                                                                className="anh61"
                                                                alt="fabysa.com"
                                                            />
                                                            <div className="anh62">
                                                                <i className="fa fa-plus"></i>
                                                                {item
                                                                    ?.allDacDiemSP
                                                                    ?.length -
                                                                    4}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                       
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThemeBlog-ConTaiNer">ThemeBlog</div>
                </div>
            )}
        </div>
    );
};
export default ThemeBlog;
