import "./DoiTac.scss";
import { getttShop, updatettShop } from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
const DoiTac = () => {
    const chieuRongMH = window.innerWidth;
    const { idShop } = useParams();
    const dispatch = useDispatch();
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const TTLoading = useSelector((state) => state.ttShop.ttShop.isFetching);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);
    const ttShopThem2 = ttShop?.ttShopThem;
    const khachSi = ttShop?.ttShopThem?.khachSi || [];
    const khachCtv = ttShop?.ttShopThem?.khachCtv || [];
    const khachLe = ttShop?.ttShopThem?.khachLe || [];
    const [sdt, setsdt] = useState("");
    const [ten, setten] = useState("");

    const handleThemKhachSi = () => {
        const kiemtraSdt = khachSi?.find((item) => item?.sdt === sdt);
        if (kiemtraSdt || !sdt || !ten) {
            alert("Người dùng đã tồn tại!");
        } else {
            const id = ttShop._id;
            const khachSi2 = {
                khachSi: [...khachSi, { sdt: sdt, ten: ten }],
            };
            const ttShopThem = { ...ttShopThem2, ...khachSi2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
            setsdt();
            setten();
            document.getElementById("input1").value = "";
            document.getElementById("input11").value = "";
        }
    };
    const handleXoaKhachSi = (item) => {
        const xoaDM = window.confirm("Xoá khách hàng này?");
        if (xoaDM) {
            const id = ttShop._id;
            const khachSi2 = {
                khachSi: khachSi?.filter((item2) => item2 !== item),
            };
            const ttShopThem = { ...ttShopThem2, ...khachSi2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
        }
    };
    const handleThemCtv = () => {
        const kiemtraSdt = khachCtv.find((item) => item?.sdt === sdt);

        if (kiemtraSdt || !sdt || !ten) {
            alert("Người dùng đã tồn tại!");
        } else {
            const id = ttShop._id;
            const khachCtv2 = {
                khachCtv: [...khachCtv, { sdt: sdt, ten: ten }],
            };
            const ttShopThem = { ...ttShopThem2, ...khachCtv2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
            setsdt();
            setten();
            document.getElementById("input2").value = "";
            document.getElementById("input22").value = "";
        }
    };
    const handleXoaCtv = (item) => {
        const xoaDM = window.confirm("Xoá khách hàng này?");
        if (xoaDM) {
            const id = ttShop._id;
            const khachCtv2 = {
                khachCtv: khachCtv?.filter((item2) => item2 !== item),
            };
            const ttShopThem = { ...ttShopThem2, ...khachCtv2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
        }
    };
    const handleThemKhachLe = () => {
        const kiemtraSdt = khachLe?.find((item) => item?.sdt === sdt);

        if (kiemtraSdt || !sdt || !ten) {
            alert("Người dùng đã tồn tại!");
        } else {
            const id = ttShop?._id;
            const khachLe2 = {
                khachLe: [...khachLe, { sdt: sdt, ten: ten }],
            };
            const ttShopThem = { ...ttShopThem2, ...khachLe2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
            setsdt();
            setten();
            document.getElementById("input3").value = "";
            document.getElementById("input33").value = "";
        }
    };
    const handleXoaKhachLe = (item) => {
        const xoaDM = window.confirm("Xoá khách hàng này?");
        if (xoaDM) {
            const id = ttShop._id;
            const khachLe2 = {
                khachLe: khachLe?.filter((item2) => item2 !== item),
            };
            const ttShopThem = { ...ttShopThem2, ...khachLe2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
        }
    };

    return (
       
          <div className="view">
          {chieuRongMH <= "1023" && (
              <div className="mobile">
                  <div className="DoiTac">
            <div>
                <div className="quayLai-tieuDe">
                    <a
                        href={`/quan-ly-shop/${idShop}`}
                        className="quayLai"
                    >
                        <i className="fa fa-angle-double-left"></i>Quay Lại
                    </a>

                    <div className="tieuDe">Khách Hàng</div>
                </div>
                <div className="nhanVien-container1">
                    <div className="nhanVien">Khách Sỉ</div>
                    <div className="sdt-them">
                        <input
                            id="input1"
                            className="sdt"
                            placeholder="Họ Và Tên"
                            type="text"
                            onChange={(e) => setten(e.target.value)}
                        />
                        <input
                            id="input11"
                            className="sdt"
                            placeholder="Số Điện Thoại"
                            type="number"
                            onChange={(e) => setsdt(e.target.value)}
                        />
                        <div className="them" onClick={handleThemKhachSi}>
                            <i className="fa fa-plus"></i>
                        </div>
                    </div>
                    {khachSi &&
                        khachSi.map((item, index) => {
                            return (
                                <div key={index} className="danhSach-xoa">
                                    <div className="danhSach">{item.ten}</div>
                                    <div className="danhSach">{item.sdt}</div>
                                    <div
                                        className="xoa"
                                        onClick={() => handleXoaKhachSi(item)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="nhanVien-container">
                    <div className="nhanVien">Cộng Tác Viên</div>
                    <div className="sdt-them">
                        <input
                            id="input2"
                            className="sdt"
                            placeholder="Họ Và Tên"
                            type="text"
                            onChange={(e) => setten(e.target.value)}
                        />
                        <input
                            id="input22"
                            className="sdt"
                            placeholder="Nhập Số Điện Thoại"
                            type="number"
                            onChange={(e) => setsdt(e.target.value)}
                        />
                        <div className="them" onClick={handleThemCtv}>
                            <i className="fa fa-plus"></i>
                        </div>
                    </div>
                    {khachCtv &&
                        khachCtv.map((item, index) => {
                            return (
                                <div key={index} className="danhSach-xoa">
                                    <div className="danhSach">{item.ten}</div>
                                    <div className="danhSach">{item.sdt}</div>
                                    <div
                                        className="xoa"
                                        onClick={() => handleXoaCtv(item)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="nhanVien-container">
                    <div className="nhanVien">Khách Lẻ</div>
                    <div className="sdt-them">
                        <input
                            id="input3"
                            className="sdt"
                            placeholder="Họ Và Tên"
                            type="text"
                            onChange={(e) => setten(e.target.value)}
                        />
                        <input
                            id="input33"
                            className="sdt"
                            placeholder="Nhập Số Điện Thoại"
                            type="number"
                            onChange={(e) => setsdt(e.target.value)}
                        />
                        <div className="them" onClick={handleThemKhachLe}>
                            <i className="fa fa-plus"></i>
                        </div>
                    </div>
                    {khachLe &&
                        khachLe?.map((item, index) => {
                            return (
                                <div key={index} className="danhSach-xoa">
                                    <div className="danhSach">{item.ten}</div>
                                    <div className="danhSach">{item.sdt}</div>
                                    <div
                                        className="xoa"
                                        onClick={() => handleXoaKhachLe(item)}
                                    >
                                        <i className="fa fa-close"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>

            {TTLoading === true && <Loading />}
        </div>
              </div>
          )}
          {chieuRongMH > "1023" && (
              <div className="pc">
                  <div className="ViDu-ConTaiNer">ViDu</div>
              </div>
          )}
      </div>
    );
};
export default DoiTac;
