import "./GioiThieuShop.scss";
import banner2 from "../assets/images/hinhNen.jpg";
import QRCode from "react-qr-code";
import QrScanner from "qr-scanner";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getttShop, updatePost } from "../redux/apiRequest";

const GioiThieuShop = (props) => {
    const { ttShop, loading, setloading } = props;
    const chieuRongMH = window.innerWidth;
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();
    const dispatch = useDispatch();
    const ttShopThem = ttShop?.ttShopThem;
    const khachSi = ttShopThem?.khachSi;
    const khachCtv = ttShopThem?.khachCtv;
    const nvBanHang = ttShopThem?.nvBanHang;
    const nvQuanLy = ttShopThem?.nvQuanLy;
    const user = useSelector((state) => state.auth.login.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const [soTien, setsoTien] = useState(0);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    // qr code

    const [dataQrCode, setdataQrCode] = useState("");
    const [result, setResult] = useState("");
    const download = () => {
        const svg = document.getElementById("QRCode");
        const svgData = new XMLSerializer().serializeToString(svg);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.onload = () => {
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const pngFile = canvas.toDataURL("image/png");
            const downloadLink = document.createElement("a");
            // name image
            downloadLink.download = `${dataQrCode}`;
            downloadLink.href = `${pngFile}`;
            downloadLink.click();
        };
        img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    };
    // read qr code
    const readCode = (e) => {
        console.log("e", e);
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        QrScanner.scanImage(file, { returnDetailedScanResult: true })
            .then((result) => setResult(result.data))
            .catch((e) => console.log(e));
    };
    // qr code
    // like Shop

    const allLikeShop = myDetail?.likeShop;
    const handeleLikeShop = (idShop) => {
        const likeShop = [
            ...myDetail?.likeShop,
            {
                idShop: idShop,
                tenVietTat: ttShop?.ttShopThem?.tenVietTat,
                tenShop: ttShop?.TenShop,
                xa: ttShop?.xa,
                huyen: ttShop?.huyen,
                tinh: ttShop?.tinh,
                sdtShop: ttShop?.sdtShop,
            },
        ];

        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const handeleDislikeShop = (idShop) => {
        const likeShop = allLikeShop?.filter((item) => item?.idShop !== idShop);
        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const likeShop = allLikeShop?.find((item) => item?.idShop === idShop);

    // like Shop
    //  Viet QR

    const BANK_ID = ttShop?.ttShopThem?.nganHang?.maSo;
    const ACCOUNT_NO = ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang;
    const TEMPLATE = "print";
    const AMOUNT = +soTien;
    const DESCRIPTION = "";
    const ACCOUNT_NAME = ttShop?.ttShopThem?.nganHang?.chuTaiKhoanNganhang;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR

    // copy
    const [copySuccess, setCopySuccess] = useState("");
    const textAreaRef = useRef(null);
    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand("copy");
        // This is just personal preference.
        // I prefer to not show the whole text area selected.
        e.target.focus();
        setCopySuccess("Copied!");
    }
    // copy
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="GioiThieuShop-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <div
                                className="quayLai"
                                onClick={() => setloading(0)}
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="tieuDe">Giới Thiệu</div>
                        </div>
                        <div className="lienHe">
                            <div className="tenShop">{ttShop?.TenShop}</div>
                            <div className="diaChi">
                                - Địa chỉ :&nbsp;
                                {ttShop?.thonXom},&nbsp;{ttShop?.xa},&nbsp;
                                {ttShop?.huyen},&nbsp;{ttShop?.tinh}{" "}
                            </div>
                            <div className="diaChi">
                                - Số điện thoại :&nbsp;
                                {ttShop?.sdtShop}
                            </div>
                            <div className="diaChi">
                                - Liên hệ online :&nbsp;&nbsp;
                                <a
                                    href={` https://zalo.me/${ttShopThem?.zalo}`}
                                    target="_blank"
                                    className="zalo"
                                >
                                    Zalo
                                </a>
                                &nbsp;-&nbsp;
                                <a
                                    href={ttShopThem?.faceBook}
                                    target="_blank"
                                    className="zalo"
                                >
                                    Facebook
                                </a>
                            </div>
                        </div>
                        <div className="nganhNghe">
                            <div className="tieuDe">Ngành Nghề</div>
                            <div
                                className="noiDung"
                                dangerouslySetInnerHTML={{
                                    __html: ttShopThem?.gioiThieu,
                                }}
                            ></div>
                        </div>
                        <div className="khachHang">
                            <div className="tieuDe">Khách Hàng</div>
                            <a
                                href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                className="noiDung"
                            >
                                Mua Lẻ
                            </a>

                            {(khachCtv?.find(
                                (item) => item?.sdtKhachCtv === user?.username
                            ) ||
                                user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvBanHang?.find(
                                    (item) =>
                                        item?.sdtnvBanHang === user?.username
                                ) ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <a
                                    className="noiDung"
                                    href={`/kho-ctv/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                >
                                    Cộng Tác Viên
                                </a>
                            )}
                            {(khachSi?.find(
                                (item) => item?.sdtKhachSi === user?.username
                            ) ||
                                user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvBanHang?.find(
                                    (item) =>
                                        item?.sdtnvBanHang === user?.username
                                ) ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <a
                                    className="noiDung"
                                    href={`/kho-si/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                >
                                    Mua Sỉ
                                </a>
                            )}
                            {/* {!myDetail ? (
                                <a href={`/dang-nhap`} className="khoCtv">
                                    Link Giới Thiệu Nhận Hoa Hồng
                                </a>
                            ) : (
                                <a
                                    href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/${myDetail?._id}/${myDetail.hoTen}/${myDetail.soDienThoai}`}
                                    className="noiDung"
                                >
                                    Link Giới Thiệu Nhận Hoa Hồng
                                </a>
                            )} */}
                        </div>

                        {/* <div className="gioiThieu-container">
                                <div className="tenShop">
                                    &nbsp;{ttShop?.TenShop}
                                </div>
                                <div className="dc">
                                    Địa chỉ:&nbsp;
                                    {ttShop?.thonXom} - {ttShop?.xa} -
                                    {ttShop?.huyen} - {ttShop?.tinh} <br />
                                    Số điện thoại:&nbsp;
                                    {ttShop?.sdtShop} <br />
                                    Hỗ trợ Online: &nbsp;
                                    <a
                                        href={` https://zalo.me/${ttShopThem?.zalo}`}
                                        target="_blank"
                                        className="zalo"
                                    >
                                        Zalo
                                    </a>
                                    ,&nbsp;
                                    <a
                                        href={ttShopThem?.faceBook}
                                        target="_blank"
                                        className="zalo"
                                    >
                                        Facebook
                                    </a>
                                </div>
                            </div>
                            <div className="gioiThieu-container2">
                                <div className="gioiThieu2">Giới Thiệu</div>
                                <div
                                    className="gt2"
                                    dangerouslySetInnerHTML={{
                                        __html: ttShopThem?.gioiThieu,
                                    }}
                                ></div>
                            </div> */}
                        {/* {(user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <div className="quanLy-container">
                                    <div className="quanLy">Quản Lý</div>
                                    <a
                                        href={`/don-hang/${ttShop?._id}`}
                                        className="donHang"
                                    >
                                        Đơn Hàng
                                    </a>
                                    <a
                                        href={`/bao-cao-shop/${tenVietTat}/${idShop}/a/${idCtv}/${tenCtv}/${sdtCtv}`}
                                        className="donHang"
                                    >
                                        Báo Cáo
                                    </a>
                                    <a
                                        href={`/doi-tac/${ttShop?._id}`}
                                        className="donHang"
                                    >
                                        Khách Hàng
                                    </a>
                                    <a
                                        href={`/tai-khoan/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/${idCtv}/${tenCtv}/${sdtCtv}/${ttShop?._id}`}
                                        className="donHang"
                                    >
                                        Fabysa Xanh
                                    </a>
                                    <a
                                        href={`/update-shop/${ttShop?._id}`}
                                        className="donHang"
                                    >
                                        Thông Tin Shop
                                    </a>
                                </div>
                            )} */}
                        {/* <div className="khoContainer">
                                <div className="dsKho">Khách Hàng</div>
                                <a
                                    href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                    className="khoCtv"
                                >
                                    Mua Lẻ
                                </a>

                                {(khachCtv?.find(
                                    (item) =>
                                        item?.sdtKhachCtv === user?.username
                                ) ||
                                    user?._id === ttShop?.user ||
                                    user?.admin === true ||
                                    nvBanHang?.find(
                                        (item) =>
                                            item?.sdtnvBanHang ===
                                            user?.username
                                    ) ||
                                    nvQuanLy?.find(
                                        (item) =>
                                            item?.sdtnvQuanLy === user?.username
                                    )) && (
                                    <a
                                        className="khoCtv"
                                        href={`/kho-ctv/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                    >
                                        Cộng Tác Viên
                                    </a>
                                )}
                                {(khachSi?.find(
                                    (item) =>
                                        item?.sdtKhachSi === user?.username
                                ) ||
                                    user?._id === ttShop?.user ||
                                    user?.admin === true ||
                                    nvBanHang?.find(
                                        (item) =>
                                            item?.sdtnvBanHang ===
                                            user?.username
                                    ) ||
                                    nvQuanLy?.find(
                                        (item) =>
                                            item?.sdtnvQuanLy === user?.username
                                    )) && (
                                    <a
                                        className="khoCtv"
                                        href={`/kho-si/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                    >
                                        Mua Sỉ
                                    </a>
                                )}
                                {!myDetail ? (
                                    <a href={`/dang-nhap`} className="khoCtv">
                                        Link Giới Thiệu Nhận Hoa Hồng
                                    </a>
                                ) : (
                                    <a
                                        href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/${myDetail?._id}/${myDetail.hoTen}/${myDetail.soDienThoai}`}
                                        className="khoCtv"
                                    >
                                        Link Giới Thiệu Nhận Hoa Hồng
                                    </a>
                                )}
                            </div> */}
                        <div className="qrcode-container">
                            <div className="qrcode">Mã QR Truy Cập Nhanh</div>
                            <div
                                onClick={download}
                                style={{
                                    height: "auto",
                                    margin: "0 auto",
                                    width: "80%",
                                }}
                            >
                                <QRCode
                                    size={256}
                                    style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                    }}
                                    value={`https://fabysa.com/${ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                    viewBox={`0 0 256 256`}
                                    id="QRCode"
                                />
                                <div className="taiqr">
                                    <i className="fa-solid fa-download">
                                        Download
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="GioiThieuShop-ConTaiNer">GioiThieuShop</div>
                </div>
            )}
        </div>
    );
};
export default GioiThieuShop;
