import "./Loading.scss";

const Loading = () => {
    return (
        <div className="loading-container">
            <div className="loading">
                <span className="loader"></span>
                <div className="capNhatDuLieu">
                    . . . Dữ Liệu Đang Cập Nhật . . .
                </div>
            </div>
        </div>
    );
};
export default Loading;
