import "./HeaderShop.scss";
import avatarTrong from "../assets/images/logo2.jpeg";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getttShop, updatePost } from "../redux/apiRequest";

const HeaderShop = (props) => {
    const {
        giaoDien,

        Tongtien,
        Tongsoluong,
        loading,
        setloading,
        handleXemAnhFull,
        setTTMenu,
        handleSapXep,
        sort,
    } = props;
    const chieuRongMH = window.innerWidth;
    const { tenVietTat, idShop, idCtv, tenCtv, sdtCtv } = useParams();

    const dispatch = useDispatch();
    const ttShop = useSelector((state) => state.ttShop?.ttShop?.ttShop?.shop);
    const ttShopThem = ttShop?.ttShopThem;
    const khachSi = ttShopThem?.khachSi;
    const khachCtv = ttShopThem?.khachCtv;
    const nvBanHang = ttShopThem?.nvBanHang;
    const nvQuanLy = ttShopThem?.nvQuanLy;
    const user = useSelector((state) => state.auth.login.currentUser);
    const myDetail = useSelector((state) => state.post.post?.myDetail);
    const [soTien, setsoTien] = useState(0);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);

    const [banner, setbanner] = useState();
    useEffect(() => {
        if (ttShop) {
            setbanner(ttShopThem?.Banner || "");
        }
    }, [ttShop]);

    // like Shop
    const allLikeShop = myDetail?.likeShop;
    const handeleLikeShop = (idShop) => {
        const likeShop = [
            ...myDetail?.likeShop,
            {
                idShop: idShop,
                tenVietTat: ttShop?.ttShopThem?.tenVietTat,
                tenShop: ttShop?.TenShop,
                xa: ttShop?.xa,
                huyen: ttShop?.huyen,
                tinh: ttShop?.tinh,
                sdtShop: ttShop?.sdtShop,
            },
        ];

        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const handeleDislikeShop = (idShop) => {
        const likeShop = allLikeShop?.filter((item) => item?.idShop !== idShop);
        const newPost = {
            likeShop: likeShop,
        };
        updatePost(newPost, myDetail._id, dispatch);
    };
    const likeShop = allLikeShop?.find((item) => item?.idShop === idShop);
    // like Shop

    //  Viet QR
    const BANK_ID = ttShop?.ttShopThem?.nganHang?.maSo;
    const ACCOUNT_NO = ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang;
    const TEMPLATE = "print";
    const AMOUNT = +soTien;
    const DESCRIPTION = "";
    const ACCOUNT_NAME = ttShop?.ttShopThem?.nganHang?.chuTaiKhoanNganhang;
    const qr = `https://img.vietqr.io/image/${BANK_ID}-${ACCOUNT_NO}-${TEMPLATE}.png?amount=${AMOUNT}&addInfo=${DESCRIPTION}&accountName=${ACCOUNT_NAME}`;
    // Viet QR
    const handleDinhDangSo = (data) => {
        const n = +data;
        return n.toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
        });
    };

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="HeaderShop-ConTaiNer">
                        <div className="headerShop">
                            <div className="tenShop">{ttShop?.TenShop}</div>
                            {myDetail ? (
                                <a
                                    href={`/ca-nhan/${tenVietTat}/${idShop}/${giaoDien}/${idCtv}/${tenCtv}/${sdtCtv}`}
                                    className="caNhan"
                                >
                                    <div className="xinChao-taiKhoan">
                                        <div className="xinChao">Xin chào!</div>
                                        <div className="taiKhoan">
                                            {handleDinhDangSo(myDetail?.cash)}
                                            &#160;<span>F&#160;</span>
                                        </div>
                                    </div>

                                    <img
                                        src={myDetail?.avatar}
                                        alt="he"
                                        className="avatar"
                                    />
                                </a>
                            ) : (
                                <a
                                    href={`/ca-nhan/${tenVietTat}/${idShop}/${giaoDien}/${idCtv}/${tenCtv}/${sdtCtv}`}
                                    className="caNhan"
                                >
                                    <div className="xinChao-taiKhoan">
                                        <div className="xinChao">Xin chào!</div>
                                        <div className="taiKhoan">
                                            {handleDinhDangSo(99000)}
                                            &#160;<span>F&#160;</span>
                                        </div>
                                    </div>
                                    <img
                                        src={avatarTrong}
                                        alt="he"
                                        className="avatar"
                                    />
                                </a>
                            )}
                        </div>
                        <div className="banner-tenShop">
                            {banner ? (
                                <img
                                    onClick={() => handleXemAnhFull(banner)}
                                    src={banner}
                                    className="banner"
                                />
                            ) : (
                                <div className="themAnh">
                                    <i className="fa fa-image"></i>
                                </div>
                            )}
                            <div className="phimChucNang">
                                <div className="phim1-phim2">
                                    {!myDetail ? (
                                        <div
                                            onClick={() =>
                                                alert("Đăng nhập để sử dụng!")
                                            }
                                            className="icon-follow"
                                        >
                                            <div className="icon">
                                                <i
                                                    className="fa fa-check-circle"
                                                    style={{
                                                        color: "gray",
                                                    }}
                                                ></i>
                                            </div>

                                            <div className="follow">
                                                Follow shop
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {likeShop &&
                                            likeShop.length !== 0 ? (
                                                <div
                                                    onClick={(e) =>
                                                        handeleDislikeShop(
                                                            idShop
                                                        )
                                                    }
                                                    className="icon-follow"
                                                >
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{
                                                                color: "red",
                                                            }}
                                                        ></i>
                                                    </div>

                                                    <div className="follow">
                                                        Follow shop
                                                    </div>
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={(e) =>
                                                        handeleLikeShop(idShop)
                                                    }
                                                    className="icon-follow"
                                                >
                                                    <div className="icon">
                                                        <i
                                                            className="fa fa-check-circle"
                                                            style={{
                                                                color: "gray",
                                                            }}
                                                        ></i>
                                                    </div>
                                                    <div className="follow">
                                                        Follow shop
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div
                                        onClick={() => setloading(8)}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="far fa-address-card"></i>
                                        </div>
                                        <div className="follow">Giới Thiệu</div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    <div
                                        onClick={() => setloading(7)}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fa fa-bank"></i>
                                        </div>
                                        <div className="follow">Ngân Hàng</div>
                                    </div>
                                    <div
                                        onClick={() => setTTMenu("hien")}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fa fa-bars"></i>
                                        </div>
                                        <div className="follow">Menu Shop</div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    <a
                                        href={`/mini-game/a/a/a/a/a/a`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-puzzle-piece"></i>
                                        </div>
                                        <div className="follow">Mini Game</div>
                                    </a>

                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-users"></i>
                                        </div>
                                        <div className="follow">
                                            Bạn Quanh Đây
                                        </div>
                                    </div>
                                </div>
                                <div className="phim1-phim2">
                                    {sort === 1 ? (
                                        <div
                                            className="icon-follow"
                                            onClick={() => handleSapXep()}
                                        >
                                            <div className="icon">
                                                <i className="fa fa-sort-numeric-desc"></i>
                                            </div>
                                            <div className="follow">
                                                Giá Giảm
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className="icon-follow"
                                            onClick={() => handleSapXep()}
                                        >
                                            <div className="icon">
                                                <i className="fa fa-sort-numeric-asc"></i>
                                            </div>
                                            <div className="follow">
                                                Giá Tăng
                                            </div>
                                        </div>
                                    )}
                                    <a
                                        href={`/${
                                            ttShop?.ttShopThem?.tenVietTat
                                        }/${ttShop?._id}/${
                                            +giaoDien * -1
                                        }/a/a/a`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-retweet"></i>
                                        </div>
                                        {+giaoDien === 1 ? (
                                            <div className="follow">
                                                Giao Diện Blog
                                            </div>
                                        ) : (
                                            <div className="follow">
                                                Giao Diện Shop
                                            </div>
                                        )}
                                    </a>
                                </div>
                                <div className="phim1-phim2">
                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-people-carry"></i>
                                        </div>
                                        <div className="follow">
                                            Nhận Giao Hàng
                                        </div>
                                    </div>
                                    <div className="icon-follow">
                                        <div className="icon">
                                            <i className="fas fa-archive"></i>
                                        </div>
                                        <div className="follow">
                                            Tìm Giao Hàng
                                        </div>
                                    </div>
                                </div>

                                <div className="phim1-phim2">
                                    <a
                                        href={`/quan-ly-shop/${idShop}`}
                                        className="icon-follow"
                                    >
                                        <div className="icon">
                                            <i className="fas fa-sitemap"></i>
                                        </div>
                                        <div className="follow"> Quản Lý</div>
                                    </a>
                                </div>
                            </div>
                        </div>

                        {/* {loading === 7 && (
                    <div className="nganHang-container">
                        <div className="quayLai-tieuDe">
                            <div
                                className="quayLai"
                                onClick={() => setloading(0)}
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="tieuDe">Tài Khoản Ngân Hàng</div>
                        </div>

                        <div className="ttTaiKhoan">Thông Tin Tài Khoản</div>
                        <div className="chiTietTaiKhoan">
                            Ngân Hàng:&nbsp;
                            {ttShop?.ttShopThem?.nganHang?.tenNganHang}
                            <br />
                            Số Tài Khoản:&nbsp;
                            {ttShop?.ttShopThem?.nganHang?.taiKhoanNganHang}
                            <br />
                            Chủ Tài Khoản:&nbsp;
                            {ttShop?.ttShopThem?.nganHang?.chuTaiKhoanNganhang}
                        </div>

                        <div className="nhapSoTien">{VND.format(soTien)}</div>

                        <div className="banPhimSo">
                            <div
                                onClick={() => setsoTien(soTien + "1")}
                                className="giaTriTien"
                            >
                                1
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "2")}
                                className="giaTriTien"
                            >
                                2
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "3")}
                                className="giaTriTien"
                            >
                                3
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "4")}
                                className="giaTriTien"
                            >
                                4
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "5")}
                                className="giaTriTien"
                            >
                                5
                            </div>
                            <div
                                onClick={() => setsoTien(soTien.slice(0, -1))}
                                className="giaTriTien"
                            >
                                <i className="fas fa-chevron-circle-left"></i>
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "6")}
                                className="giaTriTien"
                            >
                                6
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "7")}
                                className="giaTriTien"
                            >
                                7
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "8")}
                                className="giaTriTien"
                            >
                                8
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "9")}
                                className="giaTriTien"
                            >
                                9
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "0")}
                                className="giaTriTien"
                            >
                                0
                            </div>
                            <div
                                onClick={() => setsoTien(soTien + "000")}
                                className="giaTriTien"
                            >
                                .000
                            </div>
                        </div>
                        <div className="maQr">Chuyển Khoản Nhanh</div>
                        <img className="qr" src={qr} />
                    </div>
                )}
                {loading === 8 && (
                    <div className="gioiThieuChiTiet">
                        <div className="quayLai-tieuDe">
                            <div
                                className="quayLai"
                                onClick={() => setloading(0)}
                            >
                                <i className="fa fa-angle-double-left"></i>
                                Quay Lại
                            </div>
                            <div className="tieuDe">Giới Thiệu Shop</div>
                        </div>

                        <div className="gioiThieu-container">
                            <div className="gioiThieu">Liên Hệ</div>
                            <div className="tenShop">
                                &nbsp;{ttShop?.TenShop}
                            </div>
                            <div className="dc">
                                Địa chỉ:&nbsp;
                                {ttShop?.thonXom} - {ttShop?.xa} -
                                {ttShop?.huyen} - {ttShop?.tinh} <br />
                                Số điện thoại:&nbsp;
                                {ttShop?.sdtShop} <br />
                                Hỗ trợ Online: &nbsp;
                                <a
                                    href={` https://zalo.me/${ttShopThem?.zalo}`}
                                    target="_blank"
                                    className="zalo"
                                >
                                    Zalo
                                </a>
                                ,&nbsp;
                                <a
                                    href={ttShopThem?.faceBook}
                                    target="_blank"
                                    className="zalo"
                                >
                                    Facebook
                                </a>
                            </div>
                        </div>
                        <div className="gioiThieu-container2">
                            <div className="gioiThieu2">Giới Thiệu</div>
                            <div
                                className="gt2"
                                dangerouslySetInnerHTML={{
                                    __html: ttShopThem?.gioiThieu,
                                }}
                            ></div>
                        </div>
                        {(user?._id === ttShop?.user ||
                            user?.admin === true ||
                            nvQuanLy?.find(
                                (item) => item?.sdtnvQuanLy === user?.username
                            )) && (
                            <div className="quanLy-container">
                                <div className="quanLy">Quản Lý</div>
                                <a
                                    href={`/don-hang/${ttShop?._id}`}
                                    className="donHang"
                                >
                                    Đơn Hàng
                                </a>
                                <a
                                    href={`/bao-cao-shop/${tenVietTat}/${idShop}/a/${idCtv}/${tenCtv}/${sdtCtv}`}
                                    className="donHang"
                                >
                                    Báo Cáo
                                </a>
                                <a
                                    href={`/doi-tac/${ttShop?._id}`}
                                    className="donHang"
                                >
                                    Khách Hàng
                                </a>
                                <a
                                    href={`/tai-khoan/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/${idCtv}/${tenCtv}/${sdtCtv}/${ttShop?._id}`}
                                    className="donHang"
                                >
                                    Fabysa Xanh
                                </a>
                                <a
                                    href={`/update-shop/${ttShop?._id}`}
                                    className="donHang"
                                >
                                    Thông Tin Shop
                                </a>
                            </div>
                        )}
                        <div className="khoContainer">
                            <div className="dsKho">Khách Hàng</div>
                            <a
                                href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                className="khoCtv"
                            >
                                Mua Lẻ
                            </a>

                            {(khachCtv?.find(
                                (item) => item?.sdtKhachCtv === user?.username
                            ) ||
                                user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvBanHang?.find(
                                    (item) =>
                                        item?.sdtnvBanHang === user?.username
                                ) ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <a
                                    className="khoCtv"
                                    href={`/kho-ctv/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                >
                                    Cộng Tác Viên
                                </a>
                            )}
                            {(khachSi?.find(
                                (item) => item?.sdtKhachSi === user?.username
                            ) ||
                                user?._id === ttShop?.user ||
                                user?.admin === true ||
                                nvBanHang?.find(
                                    (item) =>
                                        item?.sdtnvBanHang === user?.username
                                ) ||
                                nvQuanLy?.find(
                                    (item) =>
                                        item?.sdtnvQuanLy === user?.username
                                )) && (
                                <a
                                    className="khoCtv"
                                    href={`/kho-si/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                >
                                    Mua Sỉ
                                </a>
                            )}
                            {!myDetail ? (
                                    <a href={`/dang-nhap`} className="khoCtv">
                                        Link Giới Thiệu Nhận Hoa Hồng
                                    </a>
                                ) : (
                                    <a
                                        href={`/${ttShop?.ttShopThem?.tenVietTat}/${ttShop?._id}/a/${myDetail?._id}/${myDetail.hoTen}/${myDetail.soDienThoai}`}
                                        className="khoCtv"
                                    >
                                        Link Giới Thiệu Nhận Hoa Hồng
                                    </a>
                                )}
                        </div>

                        <div className="qrcode-container">
                            <div className="qrcode">Mã QR Truy Cập Nhanh</div>
                            <div
                                onClick={download}
                                style={{
                                    height: "auto",
                                    margin: "0 auto",
                                    maxWidth: 150,
                                    width: "100%",
                                }}
                            >
                                <QRCode
                                    size={256}
                                    style={{
                                        height: "auto",
                                        maxWidth: "100%",
                                        width: "100%",
                                    }}
                                    value={`https://fabysa.com/${ttShopThem?.tenVietTat}/${ttShop?._id}/a/a/a/a`}
                                    viewBox={`0 0 256 256`}
                                    id="QRCode"
                                />
                            </div>
                            <div className="taiqr" onClick={download}>
                                <i className="fa-solid fa-download">
                                    {" "}
                                    Download
                                </i>
                            </div>
                        </div>
                    </div>
                )} */}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="HeaderShop-ConTaiNer">ViDu</div>
                </div>
            )}
        </div>
    );
};
export default HeaderShop;
