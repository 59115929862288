import "./ThemeShop.scss";
const ThemeShop = (props) => {
    const {
        allSanPham,
        VND,
        nhomSP,
        handleChiTietSP,
        handleDaThemGioHang,
        handleThemGioHang,
        cartDemo,
        setTTMenu,
    } = props;
    const chieuRongMH = window.innerWidth;
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="ThemeShop-ConTaiNer">
                        <div
                            className="kiHieu-nhomSanPham2-menu"
                            onClick={() => setTTMenu("hien")}
                        >
                            <div className="nhomSanPham2">{nhomSP}</div>
                            <div className="menu">
                                <i className="fa fa-bars"></i>
                            </div>
                        </div>
                        <div className="sanPham-container">
                            {allSanPham &&
                                allSanPham?.legth !== 0 &&
                                allSanPham?.map((item, index) => {
                                    return (
                                        <div key={index} className="sanPham">
                                            <img
                                                onClick={() =>
                                                    handleChiTietSP(item)
                                                }
                                                src={
                                                    item?.allDacDiemSP?.length >
                                                        0 &&
                                                    item?.allDacDiemSP[0]
                                                        ?.AnhSanPham
                                                }
                                                className="anhSanPham"
                                                alt="fabysa.com"
                                            />

                                            <div>
                                                {item?.TenSanPham?.length >
                                                23 ? (
                                                    <div className="tenSP">
                                                        {item?.TenSanPham.slice(
                                                            0,
                                                            20
                                                        )}
                                                        ...
                                                    </div>
                                                ) : (
                                                    <div className="tenSP">
                                                        {item.TenSanPham}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="giaCu">
                                                {item?.allDacDiemSP?.length >
                                                    0 &&
                                                    VND.format(
                                                        item?.allDacDiemSP[0]
                                                            .giaNiemYet
                                                    )}
                                            </div>
                                            <div className="giaBan-phanTram">
                                                <div className="giaBan">
                                                    {item?.allDacDiemSP
                                                        ?.length > 0 &&
                                                        VND.format(
                                                            item
                                                                ?.allDacDiemSP[0]
                                                                .giaKhuyenMai
                                                        )}
                                                </div>
                                                <div className="phanTram">
                                                    {item?.allDacDiemSP
                                                        ?.length > 0 &&
                                                        Math.floor(
                                                            (100 *
                                                                (item
                                                                    ?.allDacDiemSP[0]
                                                                    .giaKhuyenMai -
                                                                    item
                                                                        ?.allDacDiemSP[0]
                                                                        .giaNiemYet)) /
                                                                item
                                                                    ?.allDacDiemSP[0]
                                                                    .giaNiemYet
                                                        )}
                                                    %
                                                </div>
                                            </div>
                                            {cartDemo &&
                                            cartDemo?.find(
                                                (item2) =>
                                                    item2?._id === item?._id
                                            ) ? (
                                                <div
                                                    onClick={() =>
                                                        handleDaThemGioHang(
                                                            item
                                                        )
                                                    }
                                                    className="daThem"
                                                >
                                                    THÊM GIỎ HÀNG
                                                </div>
                                            ) : (
                                                <div
                                                    onClick={() =>
                                                        handleThemGioHang(item)
                                                    }
                                                    className="themGH"
                                                >
                                                    THÊM GIỎ HÀNG
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                       
                    </div>
                </div>
            )}
             {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="ThemeBlog-ConTaiNer">ThemeShop</div>
                </div>
            )}
        </div>
    );
};
export default ThemeShop;
