import "./ChiTietSanPham2.scss";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
const ChiTietSanPham2 = (props) => {
    const {
        handleDaThemGioHang,
        handleThemGioHang,
        thongTinSp,
        setloading,
        cartDemo,
        handleXemAnhFull,
    } = props;
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const nvQuanLy = ttShop?.ttShopThem?.nvQuanLy;
    const user = useSelector((state) => state.auth.login.currentUser);
    const VND = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND",
    });
    const allDacDiemSP = thongTinSp?.allDacDiemSP;
    const navigate = useNavigate();
    const suaSanPham = () => {
        const suaSp = window.confirm("Sửa sản phẩm này?");
        if (suaSp) {
            navigate(`/update-san-pham/${thongTinSp?._id}/${ttShop?._id}/shop`);
        }
    };
    console.log("thongTinSp", thongTinSp);

    return (
        <div className="chiTietSanPham-container">
            <div className="quayLai-tieuDe">
                <div onClick={() => setloading(0)} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </div>
                <div className="tieuDe">Chi Tiết Sản Phẩm</div>
            </div>
            <div className="sanPham-container">
                <div className="tenSP-themGioHang">
                    {user?._id === ttShop?.user ||
                    user?.admin === true ||
                    nvQuanLy?.find(
                        (item) => item?.sdtnvQuanLy === user?.username
                    ) ? (
                        <div onClick={() => suaSanPham()} className="tenSP">
                            <i className="fa fa-edit" style={{color:'red'}}></i>&nbsp;
                            {thongTinSp?.TenSanPham}
                        </div>
                    ) : (
                        <div className="tenSP">{thongTinSp?.TenSanPham}</div>
                    )}

                    {cartDemo?.find((item) => thongTinSp?._id === item?._id) ? (
                        <div
                            onClick={() => handleDaThemGioHang(thongTinSp)}
                            className="themGioHang"
                        >
                            Thêm Giỏ Hàng
                        </div>
                    ) : (
                        <div
                            onClick={() => handleThemGioHang(thongTinSp)}
                            className="dathemGioHang"
                        >
                            Thêm Giỏ Hàng
                        </div>
                    )}
                </div>
                <div className="bangGia">
                    {allDacDiemSP &&
                        allDacDiemSP?.length > 0 &&
                        allDacDiemSP?.map((item, index) => {
                            return (
                                <div key={index} className="gia1">
                                    <img
                                        onClick={() =>
                                            handleXemAnhFull(item.AnhSanPham)
                                        }
                                        src={item?.AnhSanPham}
                                        className="anhSp"
                                        alt="fabysa.com"
                                    />

                                    <div className="giaKm-tenSp">
                                        <div className="giaKm">
                                            {VND.format(item?.giaKhuyenMai)}
                                        </div>
                                        <div className="tenSp">
                                            {item?.tenDacDiem}
                                        </div>
                                    </div>
                                    <div className="giaNY-giam">
                                        <div className="giaNY">
                                            {VND.format(item?.giaNiemYet)}
                                        </div>
                                        <div className="giam">
                                            {Math.floor(
                                                (100 *
                                                    (item?.giaKhuyenMai -
                                                        item?.giaNiemYet)) /
                                                    item?.giaNiemYet
                                            )}
                                            %
                                        </div>
                                    </div>
                                    <div className="tinhTrang-sl">
                                        <div className="tinhTrang">
                                            Tình trạng
                                        </div>
                                        <div className="sl">
                                            {item?.soLuong}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="thongTinSp">
                    <div className="tieuDeTT">Thông Tin Sản Phẩm</div>
                    <div className="noiDungTT"> {thongTinSp?.TenSanPham}</div>
                    <div
                        className="noiDungTT"
                        dangerouslySetInnerHTML={{
                            __html: thongTinSp?.thongTinSanPham,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};
export default ChiTietSanPham2;
