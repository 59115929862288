import "./NhanVienShop.scss";
import { getttShop, updatettShop } from "../redux/apiRequest";
import Loading from "../GiaoDienChung/Loading";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
const NhanVienShop = () => {
    const chieuRongMH = window.innerWidth;
    const { idShop } = useParams();
    const dispatch = useDispatch();
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const TTLoading = useSelector((state) => state.ttShop.ttShop.isFetching);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);
    const ttShopThem2 = ttShop?.ttShopThem;
    const nvQuanLy = ttShop?.ttShopThem?.nvQuanLy || [];
    const nvBanHang = ttShop?.ttShopThem?.nvBanHang || [];
    const [sdt, setsdt] = useState("");
    const [ten, setten] = useState("");
 

    const handleThemQuanLy = () => {
        const kiemtraSdt = nvQuanLy?.find((item) => item?.sdt === sdt);
        if (kiemtraSdt || !sdt || !ten) {
            alert("Người dùng đã tồn tại!");
        } else {
            const id = ttShop._id;
            const nvQuanLy2 = {
                nvQuanLy: [...nvQuanLy, { sdt: sdt, ten: ten }],
            };
            const ttShopThem = { ...ttShopThem2, ...nvQuanLy2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
            setsdt();
            setten();
            document.getElementById("input1").value = "";
            document.getElementById("input11").value = "";
        }
    };
    const handleThemBanHang = () => {
        const kiemtraSdt = nvBanHang?.find((item) => item?.sdt === sdt);
        if (kiemtraSdt || !sdt || !ten) {
            alert("Người dùng đã tồn tại!");
        } else {
            const id = ttShop._id;
            const nvBanHang2 = {
                nvBanHang: [...nvBanHang, { sdt: sdt, ten: ten }],
            };
            const ttShopThem = { ...ttShopThem2, ...nvBanHang2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
            setsdt();
            setten();
            document.getElementById("input2").value = "";
            document.getElementById("input22").value = "";
        }
    };
    const handleXoaQuanLy = (item) => {
        const xoaDM = window.confirm("Xoá nhân viên này?");
        if (xoaDM) {
            const id = ttShop._id;
            const nvQuanLy2 = {
                nvQuanLy: nvQuanLy?.filter((item2) => item2 !== item),
            };
            const ttShopThem = { ...ttShopThem2, ...nvQuanLy2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
        }
    };
    const handleXoaBanHang = (item) => {
        const xoaDM = window.confirm("Xoá nhân viên này?");
        if (xoaDM) {
            const id = ttShop._id;
            const nvBanHang2 = {
                nvBanHang: nvBanHang?.filter((item2) => item2 !== item),
            };
            const ttShopThem = { ...ttShopThem2, ...nvBanHang2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
          
            updatettShop(newShop, id, dispatch);
        }
    };

    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="NhanVienShop-ConTaiNer">
                        <div className="quayLai-tieuDe">
                            <a
                           href={`/quan-ly-shop/${idShop}`}
                                className="quayLai"
                            >
                                <i className="fa fa-angle-double-left"></i>Quay
                                Lại
                            </a>

                            <div className="tieuDe">Nhân Viên</div>
                        </div>
                        <div className="nhanVien-container1">
                            <div className="nhanVien">Quản Lý</div>
                            <div className="sdt-them">
                                <input
                                    id="input1"
                                    className="sdt"
                                    placeholder="Họ Và Tên"
                                    type="text"
                                    onChange={(e) => setten(e.target.value)}
                                />
                                <input
                                    id="input11"
                                    className="sdt"
                                    placeholder="Số Điện Thoại"
                                    type="number"
                                    onChange={(e) => setsdt(e.target.value)}
                                />
                                <div
                                    className="them"
                                    onClick={handleThemQuanLy}
                                >
                                    <i className="fa fa-plus"></i>
                                </div>
                            </div>
                            {nvQuanLy &&
                                nvQuanLy.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="danhSach-xoa"
                                        >
                                            <div className="danhSach">
                                                {item.ten}
                                            </div>
                                            <div className="danhSach">
                                                {item.sdt}
                                            </div>
                                            <div
                                                className="xoa"
                                                onClick={() =>
                                                    handleXoaQuanLy(item)
                                                }
                                            >
                                                <i className="fa fa-close"></i>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="nhanVien-container">
                            <div className="nhanVien">Bán Hàng</div>
                            <div className="sdt-them">
                                <input
                                    id="input2"
                                    className="sdt"
                                    placeholder="Họ Và Tên"
                                    type="text"
                                    onChange={(e) => setten(e.target.value)}
                                />
                                <input
                                    id="input22"
                                    className="sdt"
                                    placeholder="Nhập Số Điện Thoại"
                                    type="number"
                                    onChange={(e) => setsdt(e.target.value)}
                                />
                                <div
                                    className="them"
                                    onClick={handleThemBanHang}
                                >
                                    <i className="fa fa-plus"></i>
                                </div>
                            </div>
                            {nvBanHang &&
                                nvBanHang.map((item, index) => {
                                    return (
                                        <div
                                            key={index}
                                            className="danhSach-xoa"
                                        >
                                            <div className="danhSach">
                                                {item.ten}
                                            </div>
                                            <div className="danhSach">
                                                {item.sdt}
                                            </div>
                                            <div
                                                className="xoa"
                                                onClick={() =>
                                                    handleXoaBanHang(item)
                                                }
                                            >
                                                <i className="fa fa-close"></i>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        {TTLoading === true && <Loading />}
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="NhanVienShop-ConTaiNer">NhanVienShop</div>
                </div>
            )}
        </div>
    );
};
export default NhanVienShop;
