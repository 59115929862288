import "./SuaMenu.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
    getttShop,
    updatettShop,
    getAllSanPham,
    updateSanPham,
} from "../redux/apiRequest";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../GiaoDienChung/Loading";
const SuaMenu = () => {
    const { idShop } = useParams();
    const ttShop = useSelector((state) => state.ttShop.ttShop.ttShop?.shop);
    const allSanPham2 = useSelector(
        (state) => state.sanPham.sanPham.arrsanPham?.allSanpham
    );
    const ttShopThem2 = ttShop?.ttShopThem;
    const tenVietTat = ttShop?.ttShopThem?.tenVietTat;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setloading] = useState(0);
    const [loaiSanPham, setloaiSanPham] = useState([]);
    const [skip, setskip] = useState(0);
    const [thongTinSp, setthongTinSp] = useState();
    const [allSanPham, setallSanPham] = useState([]);
    useEffect(() => {
        setloaiSanPham(ttShopThem2?.menuShop);
    }, [ttShop]);
    useEffect(() => {
        getttShop(idShop, dispatch);
    }, []);

    useEffect(() => {
        if (allSanPham2) {
            setallSanPham([...allSanPham, ...allSanPham2]);
        }
    }, [allSanPham2]);
    useEffect(() => {
        const handleScroll = (e) => {
            const scrollHeight = e.target.documentElement.scrollHeight;
            const currentHeight =
                e.target.documentElement.scrollTop + window.innerHeight;
            if (currentHeight >= scrollHeight) {
                setskip(skip + 20);
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [allSanPham]);
    useEffect(() => {
        const limit = 20;
        getAllSanPham(idShop, skip, limit, dispatch);
    }, [skip]);
    useEffect(() => {
        if (skip === 0) {
            setallSanPham([]);
        }
    }, [skip]);
    console.log("skip", skip);
    // Sua Nhom San Pham
    const [nhomSanPhamMoi, setnhomSanPhamMoi] = useState("");

    const handleThemNhomSanPham = () => {
        if (!nhomSanPhamMoi) {
            alert("Mời nhập nhóm mới!");
        } else {
            const id = ttShop._id;
            const menuShop2 = { menuShop: [...loaiSanPham, nhomSanPhamMoi] };
            const ttShopThem = { ...ttShopThem2, ...menuShop2 };
            const newShop = {
                ttShopThem: ttShopThem,
            };
            console.log("newShop", newShop);
            updatettShop(newShop, id, dispatch, setloading);
            setnhomSanPhamMoi();
            setloading(1);
            document.getElementById("input1").value = "";
        }
    };
    const handleXoaNhomSanPham = (item) => {
        const xoaDM = window.confirm("Xoá nhóm này?");
        if (xoaDM) {
            if (loaiSanPham?.length === 1) {
                alert("Không thể xoá toàn bộ menu!");
            } else {
                const id = ttShop._id;
                const menuShop2 = {
                    menuShop: loaiSanPham?.filter((item2) => item2 !== item),
                };
                const ttShopThem = { ...ttShopThem2, ...menuShop2 };
                const newShop = {
                    ttShopThem: ttShopThem,
                };
                updatettShop(newShop, id, dispatch, setloading);
                setloading(1);
            }
        }
    };
    const suaSanPham = (item) => {
        const suaSp = window.confirm("Sửa sản phẩm này?");
        if (suaSp) {
            navigate(`/update-san-pham/${item?._id}/${ttShop?._id}/quan-ly`);
        }
    };
    const themSanPhamMoi = () => {
        const themSp = window.confirm("Thêm sản phẩm mới?");
        if (themSp) {
            navigate(`/addsp/${idShop}`);
        }
    };
 

    return (
        <div className="SuaMenu-ConTaiNer">
            <div className="quayLai-tieuDe">
                <a href={`/quan-ly-shop/${idShop}`} className="quayLai">
                    <i className="fa fa-angle-double-left"></i>Quay Lại
                </a>
                <div className="tieuDe">Menu & Sản Phẩm</div>
            </div>

            <div className="menu-container">
                <div className="tieuDe-them">
                    <div className="tieuDe">Menu Shop</div>
                </div>
                <div className="themNhom">
                    <input
                        id="input1"
                        className="tenNhom"
                        onChange={(e) => setnhomSanPhamMoi(e.target.value)}
                        placeholder="Thêm nhóm mới . . ."
                    />
                    <div
                        className="them"
                        onClick={() => handleThemNhomSanPham()}
                    >
                        <i className="fa fa-plus-circle"></i>
                    </div>
                </div>
                <div className="menu">
                    {loaiSanPham &&
                        loaiSanPham?.length !== 0 &&
                        loaiSanPham?.map((item, index) => {
                            return (
                                <div className="tenNhomXoa" key={index}>
                                    <div className="tenNhom">
                                        {" "}
                                        {index + 1}.&nbsp;{item}
                                    </div>
                                    <div
                                        onClick={() =>
                                            handleXoaNhomSanPham(item)
                                        }
                                        className="xoa"
                                    >
                                        <i className="fa fa-remove"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="sanPham-container">
                <div className="tieuDe-them">
                    <div className="tieuDe">Sản Phẩm Shop</div>
                    <div onClick={() => themSanPhamMoi()} className="them">
                        <i className="fa fa-plus-circle"></i>
                    </div>
                </div>
                <div className="sanPham">
                    {allSanPham &&
                        allSanPham?.length !== 0 &&
                        allSanPham?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => suaSanPham(item)}
                                    className="allSanPham"
                                    key={index}
                                >
                                    <div
                                        className={
                                            item?.view === "Hiện"
                                                ? "tenSpHien"
                                                : "tenSpAn"
                                        }
                                    >
                                        {index + 1}.&nbsp; {item?.TenSanPham}
                                    </div>
                                    <div className="sua">
                                        <i className="fa fa-edit"></i>
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};
export default SuaMenu;
