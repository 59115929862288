import "./MenuShop.scss";

const MenuShop = (props) => {
    const { nhomSanPham, setnhomSP, nhomSP, handleChonNhomSanPham, setTTMenu } =
        props;
    const chieuRongMH = window.innerWidth;
    return (
        <div className="view">
            {chieuRongMH <= "1023" && (
                <div className="mobile">
                    <div className="MenuShop-ConTaiNer">
                        <div className="tieuDe-noiDung">
                            <div className="tieuDe-close">
                                <div className="tieuDe">Xin mời chọn</div>
                                <div
                                    onClick={() => setTTMenu("an")}
                                    className="close"
                                >
                                    <i className="fa fa-close"></i>
                                </div>
                            </div>

                            {nhomSanPham &&
                                nhomSanPham?.map((item, index) => {
                                    return (
                                        <div key={index}>
                                            {item === nhomSP ? (
                                                <div
                                                    className="daChon"
                                                    onClick={() =>
                                                        setTTMenu("an")
                                                    }
                                                >
                                                    {item}
                                                </div>
                                            ) : (
                                                <div
                                                    className="chuaChon"
                                                    onClick={() =>
                                                        handleChonNhomSanPham(
                                                            item
                                                        )
                                                    }
                                                    key={index}
                                                >
                                                    {item}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            )}
            {chieuRongMH > "1023" && (
                <div className="pc">
                    <div className="MenuShop-ConTaiNer">MenuShop</div>
                </div>
            )}
        </div>
    );
};
export default MenuShop;
